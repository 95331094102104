<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.AccountManager" @n-shortcut="parseTableAction({ actionType: 'create-account' })">
  <v-container fluid>
    <Dialog :stateId="dialogId"
    @dialog-closing="resetDialogs">
      <ConfirmDelete
      v-if="deleting"
      :title="$t('account')"
      @delete="deleteItem"
      @cancel-delete="resetDialogs"/>
      <AccountForm
      @account-changed="refreshAccounts"
      @account-policies-changed="fetchAccounts(tags)"
      v-if="creating || editing"
      :isEditing="editing"
      :accountId="focusedAccount.accountId"
      />
      <AccountDetail
      :accountId="focusedAccount.accountId"
      v-if="accountDetails"
      @edit-account="editAccount"
      @show-qrcode="showQrCode"/>
      <AccountQrCode
      v-if="qrCodeDialog"
      :propAccount="focusedAccount"
      @click:details="viewAccountDetails"
      />
      <PayablesByTract @close="resetDialogs" v-if="viewingPayables" :account="focusedAccount"/>
    </Dialog>
    <AccountTable
    @tags-chosen="applyFilter"
    @refresh-accounts="refreshAccounts"
    @account-action="parseTableAction"></AccountTable>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '../utils/Enumerations'

export default {
  name: 'Accounts',

  components: {
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    AccountForm: () => import('@/components/account/AccountForm.vue'),
    AccountTable: () => import('@/components/account/AccountTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    PayablesByTract: () => import('@/components/accounting/accounts-payable/PayablesByTractDialog.vue'),
    AccountDetail: () => import('@/components/account/account-detail/AccountDetail.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    AccountQrCode: () => import('@/components/account/AccountQrCode.vue')
  },

  data: () => ({
    dialogId: 'accounts-dialog',
    deleting: false,
    editing: false,
    qrCodeDialog: false,
    creating: false,
    viewingPayables: false,
    accountDetails: false,
    tags: [],
    focusedAccount: {},
    UserClaims
  }),

  created () {
    this.refreshAccounts()
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['fetchAccounts', 'deleteAccount']),

    parseTableAction (action) {
      this.resetDialogs()

      if (action.account) {
        this.focusedAccount = action.account
      }

      switch (action.actionType) {
        case 'create-account':
          this.creating = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
          break
        case 'edit-account':
          this.editing = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
          break
        case 'view-account-details':
          this.accountDetails = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
          break
        case 'view-payables':
          this.viewingPayables = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
          break
        case 'delete-account':
          this.deleting = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
          break
        case 'show-account-qrcode':
          this.qrCodeDialog = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
          break
        default: break
      }
    },

    showQrCode (account) {
      this.parseTableAction({ actionType: 'show-account-qrcode', account })
    },

    viewAccountDetails (account) {
      this.parseTableAction({ actionType: 'view-account-details', account })
    },

    async deleteItem () {
      await this.deleteAccount(this.focusedAccount.accountId)
      this.resetDialogs()
      this.refreshAccounts()
    },

    resetDialogs () {
      this.focusedAccount = {}
      this.closeDialogsAtOrAbove(this.dialogId)
      this.editing = false
      this.creating = false
      this.deleting = false
      this.accountDetails = false
      this.viewingPayables = false
      this.qrCodeDialog = false
    },

    refreshAccounts () {
      this.resetDialogs()
      this.fetchAccounts(this.tags)
    },

    editAccount (account) {
      this.resetDialogs()
      this.focusedAccount = account
      this.editing = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    applyFilter (tags) {
      this.tags = tags
      this.refreshAccounts()
    }
  }
}
</script>

<style>
  #progress-bar-table {
    position: absolute;
    left: 50%;
    top: 30%;
  }
</style>
